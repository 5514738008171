<template>
  <div class="wrapper">
    <div class="item">
      <span class="title"><span class="icon-must">*</span>发票抬头</span>
      <span class="content">
        <input
          class="uni-input"
          placeholder="请输入姓名"
          maxlength="20"
          v-model="invoiceFrom.name"
          :onkeyup="(invoiceFrom.name = invoiceFrom.name.replace(/\s+/g, ''))"
        />
      </span>
    </div>
    <div class="item">
      <span class="title"><span class="icon-must">*</span>手机号</span>
      <span class="content">
        <input
          class="uni-input"
          placeholder="请输入手机号"
          maxlength="11"
          v-model="invoiceFrom.phone"
          :onkeyup="(invoiceFrom.phone = invoiceFrom.phone.replace(/\s+/g, ''))"
          @input="tuominSave('phone')"
          @focus="tuominFocus"
        />
        <input v-model="appliMobile" style="display: none" />
      </span>
    </div>
    <div class="item">
      <span class="title"><span class="icon-must">*</span>地址</span>
      <span class="content">
        <input
          class="uni-input"
          placeholder="请输入地址"
          v-model="invoiceFrom.address"
          :onkeyup="
            (invoiceFrom.address = invoiceFrom.address.replace(/\s+/g, ''))
          "
        />
      </span>
    </div>
    <div class="item">
      <span class="title"><span class="icon-must">*</span>邮箱</span>
      <span class="content">
        <input
          class="uni-input"
          placeholder="请输入邮箱"
          v-model="invoiceFrom.email"
          :onkeyup="(invoiceFrom.email = invoiceFrom.email.replace(/\s+/g, ''))"
        />
      </span>
    </div>
    <p class="notes">注：仅支持申请个人发票</p>
    <div class="btn-box">
      <p class="submit-btn" @click="submit">确认开票</p>
      <p class="submit-cancel" @click="back">取消</p>
    </div>
  </div>
</template>
<script>
import { validIdCard, validPhone, validEmail } from "@/utils/validate";
import { getInvoice } from "@/utils/api.js";
export default {
  data() {
    return {
      invoiceFrom: {
        name: "",
        phone: "",
        address: "",
        email: "",
      },
      appliMobile: "",
      queryData: {},
    };
  },
  mounted() {
    this.queryData = JSON.parse(sessionStorage.getItem("invoiceData"));
    this.invoiceFrom.name = this.queryData.appliName;
    this.invoiceFrom.phone = this.queryData.secAppliMobile;
    this.appliMobile = this.queryData.appliMobile;
  },
  methods: {
    tuominFocus() {
      this.invoiceFrom.phone = "";
    },
    //脱敏-将数据从List对象保存到用于存储的tuomin对象中
    tuominSave(type) {
      console.log(type);
      switch (type) {
        case "name":
          this.tuomin.clientName = this.List.clientName;
          break;
        case "cert":
          this.tuomin.certNo = this.List.certNo;
          break;
        case "phone":
          this.appliMobile = this.invoiceFrom.phone;
          break;
      }
    },
    back() {
      this.$router.push({
        path: "/orderList",
      });
    },
    submit() {
      if (!this.invoiceFrom.name) {
        this.$toast("请填写发票抬头");
      } else if (!this.appliMobile) {
        this.$toast("请填写手机号");
      } else if (!validPhone(this.appliMobile)) {
        this.$toast("请填写正确的手机号");
      } else if (!this.invoiceFrom.address) {
        this.$toast("请填写地址");
      } else if (!this.invoiceFrom.email) {
        this.$toast("请填写邮箱");
      } else if (!validEmail(this.invoiceFrom.email)) {
        this.$toast("请填写正确的邮箱");
      } else {
        let params = {
          // policyNo:this.$route.query.policyNo,
          invoiceTitle: this.invoiceFrom.name,
          phone: this.appliMobile,
          address: this.invoiceFrom.address,
          email: this.invoiceFrom.email,
        };
        if(this.$route.query.policyNo) {
          params.policyNos = [this.$route.query.policyNo];
        }
        getInvoice(params).then((res) => {
          if (res.success) {
            this.$toast(res.message);
            let code = "";
            if (res.code == 200) {
              code = "200";
            } else {
              code = "500";
            }
            this.$router.push({
              path: "/invoiceStatus",
              query: {
                code: code,
              },
            });
          } else {
            this.$toast(res.message);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.wrapper {
  padding: 15px 15px;
  .item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f7f7f7;
    .title {
      display: inline-block;
      width: 84px;
      text-align: left;
      // height: 15px;
      // line-height: 15px;
      font-size: 16px;
      color: #333333;
      margin-right: 27px;
      position: relative;
      .icon-must {
        color: #ff7418;
        position: absolute;
        top: 3px;
        left: -10px;
      }
    }
    .content {
      font-size: 16px;
      color: #333333;
      .uni-input {
        height: 35px;
      }
    }
  }
  .notes {
    color: #ff7418;
    font-size: 13px;
    color: #ff7418;
    height: 15px;
    line-height: 15px;
    margin-top: 13px;
  }
  .btn-box {
    margin-top: 43px;
    .submit-btn {
      width: 325px;
      height: 45px;
      background: #ff7418;
      border-radius: 8px;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: 45px;
      margin: 0 auto;
    }
    .submit-cancel {
      width: 325px;
      height: 45px;
      border-radius: 8px;
      font-size: 15px;
      border: 1px solid #ff7418;
      font-weight: bold;
      color: #ff7418;
      margin: 0 auto;
      text-align: center;
      line-height: 45px;
      margin-top: 15px;
    }
  }
}
</style>